import React from "react";
import { Col, Row } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// styles
const Historyyear = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin: 15px 0 0 0;
  font-size: 1.3rem;
`

const Historytitle = styled.h5`
  color: ${({ theme }) => theme.colours.primary.main};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 1.5rem;
`


const Historycontent = styled.div`
  color: ${({ theme }) => theme.colours.common.black};
  
  div {
    line-height: 1.2;
  }

  & a {
    color: ${({ theme }) => theme.colours.primary.main};
    text-decoration: none;
    transition: ${({ theme }) => theme.animations.link};
  }
  
  & a:hover {
    color: ${({ theme }) => theme.colours.primary.main};
    font-weight: bold;
  }
  
  @media (min-width: 992px) { 
  }
`

const Historyimg = styled(GatsbyImage)`
  margin: 0 auto;
  z-index: -1;
`


function Historylist({ children }) {
 
  const data = useStaticQuery(graphql`
  query {
    directus {
      concut_history(filter: {status: {_eq: "Published"}}, sort: ["-year"]) {
        sort
        year
        title
        content
        history_image {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }  
  `)

  const historyNode = data.directus.concut_history;
 
  return (
    
    <>
      <Row className="mt-5">
        <Col className="timeline timeline-one" xs={12} lg={12}>

        {historyNode.map((historyItem, i) => 
        (
          
              <div className="timeline-item" key={i}> {(() => {
              return (
              <>
                  <Historyyear className="my-3">{historyItem.year}</Historyyear>
                  <Historytitle>{historyItem.title}</Historytitle>
                  <Historycontent dangerouslySetInnerHTML={{
                        __html: historyItem.content,
                    }} />
                  {historyItem.history_image && <Historyimg image={historyItem.history_image.imageFile.childImageSharp.gatsbyImageData} alt={historyItem.title} objectPosition="center center" />}
              </>
              )
              })()}
              </div>
             ))}    

        </Col>
      </Row>    
  </>
  );
}

export default Historylist;