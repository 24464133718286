import React from "react"
import Sitenav from "../components/sitenav"
import Sitefooter from "../components/sitefooter"
import { Container, Row } from "react-bootstrap"


function Pagelayout ({ children, logo, footerimage }) {

return (
    <>
    <Container>
        <Row className="inner mb-5">
            <Sitenav logo={logo} />
        </Row>
        {children}
    </Container>
    <Sitefooter />
    </>
    )
}

export default Pagelayout
