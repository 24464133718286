import React from "react"
import Pagelayout from "../layouts/page"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Col, Row, Button} from "react-bootstrap"
import styled from "styled-components"
import Historylist from "../components/historylist"

// styles
const Pageheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 20px auto;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};

  @media (min-width: 992px) { 
    margin: 10px 0 50px 0;
  }
`

function Aboutpage ({ data }) {

  return (
    <>
    <Seo title={`Our History - ${data.directus.concut_settings.site_title}`} description={data.directus.concut_settings.site_description} author={data.directus.concut_settings.site_author}  />
    <Pagelayout logo={data.directus.concut_settings.site_logo} footerimage={data.directus.concut_settings.footer_image} >
        <Row>
          <Col>
                <Pageheading>Our History</Pageheading>
          </Col>
        </Row>
        <Historylist />
    </Pagelayout>
    </>
  )
}


export default Aboutpage

export const query = graphql`
query {
  directus {
    concut_settings {
      site_title
      site_description
      site_author
      site_logo {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 300
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      contact_details
    }
    concut_home {
      seo_title
    }
  }
}
`